<script>
import { OptionsComponent } from '@drapejs/core';
import useMeta from "@/composables/useMeta";

export default {
  extends: OptionsComponent,
  data: () => ({
    requestPending: false,
  }),
  computed: {
    error() {
      if (!this.$route.query.orderNo && !this.$route.query.invoiceNo) {
        return this.$globalTexts.orderdetails__ordernotfound;
      }

      if (!this.requestPending && !this.document) {
        return this.$globalTexts.orderdetails__ordernotfound;
      }

      return '';
    },
    isInvoice() {
      return this.$route.query.invoiceNo;
    },
    document() {
      return this.$page.orderDetails;
    },
  },
  methods: {
    getImageContainerStyle(row) {
      if (row.imageSystemId == '00000000-0000-0000-0000-000000000000') {
        return undefined;
      }
      return this.$toLitiumMediaUrl(row.imageSystemId, { maxHeight: 98 });
    },
    showMyOrderList() {
      window.dispatchEvent(new Event('mypages-orderlist'));
    },
  },
  created() {
    useMeta();
  },
};
</script>

<template>
  <div class="order-status-page">
    <div class="order-status-page__container">
      <div v-if="error" class="order-status-page__error">
          {{ error }}
      </div>

      <div v-if="document && document.no && !error">
        <div class="order-status-page__header">
          <div class="order-status-page__title" v-if="!isInvoice">
            {{$globalTexts.orderdetails__order_title}} {{document.no}}
          </div>
          <div class="order-status-page__title" v-if="isInvoice">
            {{$globalTexts.orderdetails__invoice_title}} {{document.no}}
          </div>

          <button 
            class="primary-button order-status-page__back"
            @click="showMyOrderList"
          >
            {{$globalTexts.orderdetails__show_all}}
            <img class="order-status-page__back-icon" src="../static/icons/chevron_right_icon.svg">
          </button>
        </div>

        <div class="order-status-page__panels">
          <div class="order-status-page__top-sub-panel">
            <div class="order-status-page__sub-panel order-status-page__customer-container">
              <div class="order-status-page__sub-panel-wrapper--half-size">
                <div class="order-status-page__panel-title">{{$globalTexts.orderdetails__customer}}</div>
                <div>{{document.customer.name}}</div>
                <div>{{document.customer.name2}}</div>
                <div>{{document.customer.address}}</div>
                <div>{{document.customer.address2}}</div>
                <div>{{document.customer.postCode}} {{document.customer.city}}</div>
                <div>{{document.customer.country}}</div>
              </div>
              <div class="order-status-page__sub-panel-wrapper--half-size">
                <div class="order-status-page__panel-title">{{$globalTexts.orderdetails__order_date}}</div>
                <div>{{document.date}}</div>

                <div class="order-status-page__goods-label-container">
                  <div class="order-status-page__panel-title">{{$globalTexts.orderdetails__goods_label}}</div>
                  <div>{{document.noteOfGoods}}</div>
                </div>
              </div>
            </div>
            <div class="order-status-page__sub-panel">
              <div class="order-status-page__sub-panel-wrapper--half-size">
                <div class="order-status-page__panel-title">{{$globalTexts.orderdetails__delivery_address}}</div>
                <div>{{document.shipping.name}}</div>
                <div>{{document.shipping.name2}}</div>
                <div>{{document.shipping.address}}</div>
                <div>{{document.shipping.address2}}</div>
                <div>{{document.shipping.postCode}} {{document.shipping.city}}</div>
                <div>{{document.shipping.country}}</div>
              </div>
              <div class="order-status-page__sub-panel-wrapper--half-size">
                <div class="order-status-page__panel-title">{{$globalTexts.orderdetails__reference}}</div>
                <div>{{document.shipping.contactName || document.customer.contactName}}</div>
                <div>{{document.shipping.contactPhone || document.customer.contactPhone}}</div>
                <div>{{document.shipping.contactEmail || document.customer.contactEmail}}</div>
              </div>
            </div>
          </div>
          <div class="order-status-page__grid-wrapper">
            <div class="order-status-page__rows-container">
              <div>
                <div class="order-status-page__panel-title">{{document.shipping.date}}</div>
              </div>
              <div v-for="row in document.rows" :key="row.lineNumber">
                <div
                  v-if="row.quantity"
                  class="order-status-page__row"
                >
                  <component
                    :is="getImageContainerStyle(row) ? 'img' : 'div'"
                    class="order-status-page__row-image-container"
                    :src="getImageContainerStyle(row)"
                  >
                  </component>

                  <div class="order-status-page__row-details">
                    <div class="order-status-page__row-details-name">{{row.description}}</div>
                    <div class="order-status-page__row-details-sku">{{row.sku}}</div>
                    <div class="order-status-page__row-details-info">
                      <div>{{row.quantity}} {{$globalTexts.orderdetails__pcs}}</div>
                      <div>{{row.price}} {{document.currency}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="order-status-page__sub-panel order-status-page__total-container">
            <div class="order-status-page__total-text">
              <div>{{$globalTexts.orderdetails__grand_total}}</div>
              <div>{{$globalTexts.orderdetails__vat}}</div>
            </div>
            <div class="order-status-page__total-values">
              <div class="order-status-page__panel-title">{{document.price}} {{document.currency}}</div>
              <div class="order-status-page__order-vat">{{document.vat}} {{document.currency}}</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style>
.order-status-page {
  color: #000000;
  background-color: var(--color-neutrals-10);
  line-height: 22px;
}

.order-status-page__container {
  margin: 0 auto;
  padding: 2rem 0;
  max-width: 1260px;
}

.order-status-page__error {
  color: var(--color-feedback-error);
  text-align: center;
  font-size: 24px;
  line-height: 22px;
  font-weight: 600;
}

.order-status-page__header {
  display: flex;
  flex-direction: column-reverse;
  padding: 0 10px;
}

.order-status-page__title {
  font-family: Mulish;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  padding-top: 1.5rem;
}

.order-status-page__back {
    position: relative;
  }

.order-status-page__back-icon {
  filter: invert(1);
  height: 16px;
  position: absolute;
  right: 12.8px;
  top: 12px;
}

.order-status-page__panels {
  display: grid;
  gap: 30px;
  grid-template-areas:
      "customer"
      "product"
      "total";
  margin-top: 1.5rem;
  padding: 0 10px;
  font-family: Mulish;
  font-size: 14px;
}

.order-status-page__panel-title {
  font-family: Mulish;
  font-weight: bold;
  font-size: 16px;
}

.order-status-page__top-sub-panel {
  box-shadow: var(--shadow-outer-05);
  grid-area: customer;
}

.order-status-page__sub-panel {
  background-color: var(--color-neutrals-00);
  padding: 14px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.order-status-page__sub-panel-wrapper--half-size {
  width: 100%;
  margin-bottom: 1rem;
}

.order-status-page__sub-panel-wrapper--half-size:last-child {
  margin-bottom: 0;
}

.order-status-page__customer-container {
  border-bottom: 2px solid #f2f2f2;
}

.order-status-page__goods-label-container {
  margin-top: 1rem;
}

.order-status-page__total-container{
  box-shadow: var(--shadow-outer-05);
  grid-area: total;
  display: none;
}

.order-status-page__order-vat {
  font-size: 14px;
}

.order-status-page__total-container {
  display: flex;
  flex-direction: row;
  line-height: 24px;
}

.order-status-page__total-values {
  text-align: right;
}

.order-status-page__grid-wrapper {
    grid-area: product;
}

.order-status-page__rows-container {
  background-color: var(--color-neutrals-00);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  box-shadow: var(--shadow-outer-05);
}

.order-status-page__row {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-start;
}

.order-status-page__row-image-container {
  width: 40px;
  height: 49px;
  object-fit: cover;
  object-position: center;
}

.order-status-page__row-details {
  padding-left: .5rem;
  line-height: 14px;
  font-size: 12px;
  flex-grow: 1;
}

.order-status-page__row-details-name {
  line-height: 16px;
  font-weight: 600;
  color: var(--color-black-90);
}

.order-status-page__row-details-sku {
  line-height: 16px;
  color: var(--color-neutrals-80);
}

.order-status-page__row-details-info {
  font-weight: 600;
  color: #000000;
  display: flex;
  justify-content: space-between;
}

@media (--tabletAndDesktop) {
  .order-status-page {
    margin: auto;
  }

  .order-status-page__header {
    display: block;
    position: relative;
    text-align: center;
    padding: 0 1rem;
    height: 40px;
  }

  .order-status-page__title {
    display: inline;
    font-size: 31px;
    line-height: 40px;
  }

  .order-status-page__back {
    position: absolute;
    right: 0;
    width: 258px;
  }

  .order-status-page__panels {
    gap: 20px 30px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "customer product"
      "total product";
    margin-top: 2rem;
    padding: 0;
  }

  .order-status-page__sub-panel {
    flex-direction: row;
    padding: 30px;
  }

  .order-status-page__sub-panel-wrapper--half-size {
    width: 50%;
    margin-bottom: 0;
  }

  .order-status-page__total-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    font-size: 16px;
    line-height: 24px;
  }

  .order-status-page__total-text,
  .order-status-page__total-values {
    width: 25%;
  }

  .order-status-page__rows-container {
    padding: 30px;
    margin-top: 0;
  }
}

</style>
